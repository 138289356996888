<script>
export default {
  data() {
    return {
      userInfo: {}
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo () {
      const user = localStorage.getItem("user");
      if (user) {
        this.userInfo = JSON.parse(user);
      }
    },

    getMemberGradeName (memberGrade) {
      if (memberGrade === 0) {
        return '준회원'
      } else if (memberGrade === 1) {
        return '정회원'
      } else if (memberGrade === 2) {
        return '운영진'
      }
    },
    getMemberPositionName (memberPosition) {
      switch (memberPosition) {
        case 0 :
          return ''
        case 1 :
          return '코치'
        case 2 :
          return '감독'
      }
    }
  }
}
</script>