<script>
import {useRoute} from 'vue-router';
import CommonHeader from "@/components/common/CommonHeader.vue";
import UserMixin from "@/mixins/UserMixin.vue";
import router from "@/router";

export default {
  data() {
    return {
      showHeader: true,
    }
  },
  mixins: [UserMixin],
  components: {CommonHeader},
  setup() {
    const route = useRoute();
    return {
      currentPath: route.path
    }
  },
  methods: {
    goRoute(path) {
      this.$router.push(path);
    },
    logout() {
      this.$store
          .dispatch('LOGOUT')
          .then(() => this.redirect())
          .catch(error => {
            alert(error);
          });
    },
    redirect() {
      this.$router.push('/login');
    },
    getImgUrl(imgUrl) {
      return require(`@/assets/img/${imgUrl}`);
    },
  }
}
</script>

<template>
  <header class="bg-black d-flex justify-content-center pt-1 pb-1 mt-4" v-if="this.$route.meta.showHeader">
    <div class="text-center fw-bold text-white me-auto ms-2">
      <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
        <template #button-content>
          <font-awesome-icon :icon="['fas', 'bars']" class="fw-bold"/>
        </template>

        <b-dropdown-group id="dropdown-group-1" header="사용자 메뉴">
            <b-dropdown-item @click="this.logout" class="ms-2">로그아웃</b-dropdown-item>
        </b-dropdown-group>
        <b-dropdown-group id="dropdown-group-2" header="관리자 메뉴" v-if="this.userInfo.memberGrade > 1">
          <router-link :to="{ path: '/games/attend' }" class="text-decoration-none fw-bold">
            <b-dropdown-item class="ms-2">출석 조회</b-dropdown-item>
          </router-link>
          <router-link :to="{ path: '/members' }" class="text-decoration-none fw-bold">
            <b-dropdown-item class="ms-2">멤버 관리</b-dropdown-item>
          </router-link>
          <router-link :to="{ path: '/teams' }" class="text-decoration-none fw-bold">
            <b-dropdown-item class="ms-2">팀 관리</b-dropdown-item>
          </router-link>
        </b-dropdown-group>
      </b-dropdown>
      <text @click="this.goRoute('/')">ARES</text>
    </div>

    <div class="ms-auto pt-1 me-2 d-flex align-middle">
      <text class="pt-1 fs-6 fw-bold text-white me-2 text-center">{{ this.userInfo.memberName }}</text>
      <div class="align-middle">
        <img :src=this.getImgUrl(this.userInfo.imgUrl) style="width: 2rem; height: 2rem; border-radius: 50%"
             v-if="this.userInfo.imgUrl !== null && this.userInfo.imgUrl !== undefined"/>
      </div>
    </div>
  </header>
  <b-container>
    <div class="border shadow-sm rounded">
      <router-view/>
    </div>
  </b-container>
  <!--  <footer class="bg-black text-white fixed-bottom" v-if="this.$route.meta.showFooter">-->
  <!--    <div class="align-middle">-->
  <!--      <ul class="list-unstyled d-flex justify-content-center mb-0 pt-1 pb-1">-->
  <!--        <li class="fs-3">-->
  <!--          <router-link :to="{ path: '/attend' }" class="text-white">-->
  <!--            <font-awesome-icon :icon="['far', 'bell']"/>-->
  <!--          </router-link>-->
  <!--        </li>-->
  <!--      </ul>-->
  <!--    </div>-->
  <!--  </footer>-->

</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.image-container {
  width: 2.4em; /* 원하는 크기로 설정 */
  height: 2.4em;
  overflow: hidden; /* 이미지가 div를 넘어가지 않도록 */
}

.image-container img {
  width: 100%;
  height: 100%;

  object-fit: cover; /* cover, contain, fill, scale-down, none 중 선택 */
}
</style>
